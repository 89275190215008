const openDialogButtons = document.querySelectorAll(".js-open-dialog");

if (openDialogButtons) {
  openDialogButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const dialogId = button.dataset.targetDialog;
      const dialog = document.getElementById(dialogId);
      const closeButton = dialog.querySelector(".js-close-dialog");

      if (dialog) {
        dialog.showModal();
      }

      if (closeButton) {
        closeButton.addEventListener("click", () => {
          dialog.close();
        });
      }
    });
  });
}
