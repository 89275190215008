import $ from 'jquery'

function checkViewport() {
    const list = $('.table-of-contents').find('ul')
    const newItem = $('.table-of-contents').find('.current_page_inactive')
    if($(window).width() < 700)  {
        addHeaderToList()
    } else {
        if(newItem.length > 0) {
            list.show()
            $('.current_page_item').remove()
            newItem.removeClass('current_page_inactive').addClass('current_page_item')
        }
    }
}

function addHeaderToList() {
    if(!$('.page_item.current_page_inactive').length > 0) {
        const item = $('.table-of-contents').find('.current_page_item')
        const list = item.parent('ul')
        list.hide()
        const newItem = item.clone()
        item.removeClass('current_page_item').addClass('current_page_inactive')
        list.parent().prepend(newItem)
        newItem.replaceWith( '<h3 class="current_page_item">' + newItem.html() + '</h3>' );
    }
}

function toggleList(e) {
    e.preventDefault()
    const list = $(this).next('ul')
    list.slideToggle()
    $(this).toggleClass('active')
}

if ( $( 'body' ).first().hasClass( 'page-child' ) ) {
    $(document).ready( checkViewport )
    $(window).on('resize', checkViewport)
    $(document).on('click', 'h3.current_page_item', toggleList )
}