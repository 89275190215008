const statCards = document.querySelectorAll(".js-stat-card");

statCards.forEach((card) => {
  const expandButton = card.querySelector(".js-stat-card-expand");

  if (expandButton) {
    expandButton.addEventListener("click", () => {
      card.classList.toggle("is-additional-content-expanded");
      const buttonText = expandButton.querySelector(".stat-card__button-text")
      if (card.classList.contains('is-additional-content-expanded')) {
        buttonText.innerHTML = '<span class="u-sr-only">collapse section</span>'
      } else {
        buttonText.innerHTML = '<span class="u-sr-only">expand section</span>'
      }
    });
  }
});